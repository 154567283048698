import React, { Component } from "react";

class Timer extends Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date()};
    }

    componentDidMount() {
        this.timerID = setInterval(() => {
            this.tick()
        }, 1000);
    }
  
    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            date: new Date()
        })
    }

    render() {
        return (
            <div class="nav-right">
                <span>{this.state.date.toLocaleTimeString()}</span>
            </div>
        )
    }
}

export default Timer;