import React from "react";
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
    NavLink
  } from "react-router-dom";
import Timer from "../../container/Timer/Timer";
import Content from "../content/Content";

const Header = () => {
    return (
        <Router>
        <div>
            <header>
                <nav id="mainNav" className="navbar">
                    <Timer />
                    <ul>
                    <li>
                        <NavLink end to="/"
                        >
                        Welcome</NavLink>
                    </li>
                    <li>
                        <NavLink to="/profile" className={({ isActive }) => (isActive ? " active" : undefined)} >Profil</NavLink>
                    </li>
                    <li>
                        <NavLink to="/portfolio" className={({ isActive }) => (isActive ? " active" : undefined)}>Portofolio</NavLink>
                    </li>
                    </ul>
                </nav>
            </header>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
            <Switch>
                <Route path="/profile" element={<Content page="profile" />}>
                    
                </Route>
                <Route path="/portfolio" element={<Content page="portfolio" />}>
                    
                </Route>
                <Route path="/" element={<Content page="welcome" />}>
                    
                </Route>
            </Switch>
        </div>
        </Router>
    )
}

export default Header;