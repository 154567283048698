import React from "react";

const Profile = () => {
    return (
        <div className="box right">
            <section className="box-desc" id="Profil">
                <article>
                    <h2 className="title">Profil Saya</h2>
                    <p className="hr-item" />
                    <div className="data-profile">
                        <p className="text-name">Nama : Budi Prasetyo</p>
                        <p className="text-ttl">TTL : Grobogan, 05 Januari 1991</p>
                        <p className="text-phone">No.Hp : 082242116118</p>
                        <p className="text-address">Alamat : Kendal, Jawa Tengah</p>
                    </div>
                </article>
            </section>
        </div>
    )
}

export default Profile;