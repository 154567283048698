import React from "react";

const Footer = () => {
    return (
        <footer>
            &copy; 2022, Budi Prasetyo
        </footer>
    )
}

export default Footer;