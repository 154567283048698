import React from "react";
import Portfolio from "../../container/Portfolio/Portfolio";
import Cover from "../cover/Cover";
import Profile from "../profile/Profile";
import Welcome from "../welcome/Welcome";

const Content = (props) => {
    let page = "";
    if (props.page === 'welcome') {
        page = <Welcome />;
    }else if (props.page === 'profile') {
        page = <Profile />;
    }else if (props.page === 'portfolio') {
        page = <Portfolio />;
    }

    return (
        <main>
            <div className="container">
                <Cover />
                {page}
            </div>
        </main>
    )
}

export default Content;