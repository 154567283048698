import React from "react";

const Welcome = () => {
    return (
        <div className="box right">
            <section className="box-desc" id="Welcome">
                <article>
                    <h2 className="title">Hallo, Selamat datang di Portofolio Saya</h2>
                    <p className="hr-item" />
                    <p>Perkenalkan nama saya Budi Prasetyo dan saat ini sebagai Web Developer. 
                        <br/>Sekarang sedang belajar React JS & React Native.. </p>
                </article>
                <p className="text-email">budi.kerja8@gmail.com</p>
                <p className="text-muted">09 Februari 2023</p>
            </section>
        </div>
    )
}

export default Welcome;