import React, { Component } from "react";

class Portfolio extends Component {
    render() {
        return (
            <div className="box right">
                <section className="box-desc" id="Portofolio">
                    <article>
                        <h2 className="title">Portofolio Saya</h2>
                        <p className="hr-item" />
                        <form>
                            Cari Bahasa Pemrograman:
                            <select id="mySelect">
                            <option value="all">Semua</option>
                            <option value="codeigniter">Codeigniter</option>
                            <option value="laravel">Laravel</option>
                            <option value="vue">Vue JS</option>
                            </select>
                        </form>
                        <div className="data-profile">
                            <p id="codeigniter" className="language">- Aplikasi Inventory menggunakan Codeigniter</p>
                            <p id="laravel" className="language">- Aplikasi Point Of Sales menggunakan Laravel</p>
                            <p id="vue" className="language">- Ecommerce menggunakan Vue JS</p>
                        </div>
                    </article>
                </section>
            </div>
        ) 
    }
}

export default Portfolio;