import React from "react";

const Cover = () => {
    return (
        <div className="box left">
            <aside>
                <div className="cover-photo">
                    <img id="Imgs" src="assets/myphoto.jpg" alt="Foto Saya" />
                    <h1>Budi Prasetyo</h1>
                    <p className="text-muted">Web Developer</p>
                </div>
            </aside>
        </div>
    )
}

export default Cover;